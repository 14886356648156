<template>
  <vx-card style="padding-left: 10px" :key="rerenderKey">
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="users"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between">
          <div style="float: left">
            <vs-select
              placeholder="10"
              autocomplete
              vs-multiple
              v-model="dataTableParams.limit"
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item,index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="clinicName">Clinic Name</vs-th>
          <vs-th sort-key="firstName">First Name</vs-th>
          <vs-th sort-key="lastName">Last Name</vs-th>
          <vs-th sort-key="email">Email</vs-th>
          <vs-th sort-key="status">Status</vs-th>
          <vs-th sort-key="hasApprovedByAdmin">Admin Approval</vs-th>
          <vs-th sort-key="createdAt">Created At</vs-th>
          <vs-th size="25%">Action</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].clinicName">{{ data[indextr].clinicName | capitalize }}</vs-td>
            <vs-td :data="data[indextr].firstName">{{ data[indextr].firstName | capitalize }}</vs-td>
            <vs-td :data="data[indextr].firstName">{{ data[indextr].lastName | capitalize}}</vs-td>
            <vs-td :data="data[indextr].email">{{ data[indextr].email }}</vs-td>
            <vs-td :data="data[indextr].status">
              <p v-if="data[indextr].status ==='Pending_Approval'" class="text-sm">Pending Approval</p>
              <p v-else class="text-sm">{{data[indextr].status}}</p>
            </vs-td>
            <vs-td>{{data[indextr].hasApprovedByAdmin}}</vs-td>
            <vs-td :data="data[indextr].createdAt">{{ data[indextr].createdAt | date_formatter }}</vs-td>
            <vs-td :data="data[indextr]._id">
              <pair-device v-if="check_has_permission('addPairDevice')" style="float: left" :clinicId="data[indextr]._id" class="m-1" />
              <view-pair-device  v-if="check_has_permission('viewPairDevice')" class="m-1" :clinicId="data[indextr]._id" />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="m-2">
        <span class="mr-2">
          {{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
          }}
          -
          {{
          totalDocs - dataTableParams.page * dataTableParams.limit > 0
          ? dataTableParams.page * dataTableParams.limit
          : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import pairDevice from "../../components/others/addPairDevice";
import pairDeviceList from "../../components/others/pairDeviceList";
import UnassociateClinic from "../../components/others/UnassociateClinic";

export default {
  components: {
    pairDevice,
    "view-pair-device": pairDeviceList,
    "unassociate-clinic": UnassociateClinic
  },
  props: {},
  data() {
    return {
      noDataText: "Loading...",
      rerenderKey: 0,
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
        userType: "clinic"
      },
      serverResponded: false,
      users: [],
      date: new Date(),
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 }
      ],
      awaitingSearch: null
    };
  },
  methods: {
    ...mapActions("clinic", ["fetchToPairClinics"]),
    getClinicsList() {
      let self = this;
      this.fetchToPairClinics(self.dataTableParams).then(res => {
        self.users = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
        self.serverResponded = true;
        self.noDataText = "No Clinics found";
      });
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getClinicsList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getClinicsList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getClinicsList();
    },
    editDetailHandler(id) {
      this.$router.push({ name: this.editRoute, params: { clinicId: id } });
    },
    createClinicHandler() {
      this.$router.push({ name: this.createRoute });
    },
    viewDetailHandler(id) {
      this.$router.push({
        name: this.viewRoute,
        params: { clinicId: id }
      });
    },
    forceRerender() {
      this.rerenderKey += 1;
    }
  },
  watch: {
    "dataTableParams.page": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getClinicsList();
      }
    },
    "dataTableParams.limit": function(newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getClinicsList();
      }
    },
    rerenderKey: function(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.getClinicsList();
      }
    }
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  created() {
    this.getClinicsList();
  }
};
</script>
